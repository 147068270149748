import {
  ProjectDescription,
  ProjectStatusKey,
} from '../../pages/webapp/projects/projects.interface';

export enum ROLLUP_VIEW_TYPES {
  PROJECTS = 'project_list',
  BUDGET_LINE_ITEMS = 'budget_line',
  TAGS = 'budget_tag',
  ACCOUNTS = 'gl_account',
}

export interface IProjectTags {
  capex: number;
  opex: number;
  priority: number;
  approved: number;
}

export interface IRollupProject {
  id: number;
  title: string;
  spend_category: number;
  status: number;
  project_status: IProjectStatus;
  priority: boolean;
  type: number;
  project_type: IProjectType;
  approved: boolean;
  property_id: number;
  start_date: string;
  project_property: IProjectProperty;
  cash_flow: ICashFlow;
  budget_tags: IBudgetTag;
  work_percentage: number;
  days_due: number;
  tags: string[];
  description: Partial<ProjectDescription>;
  project_id: string;
}

export interface IRollupProjectTable {
  id: number;
  title: string;
  spend_category: number;
  status: number;
  project_status: IProjectStatus;
  start_date: string;
  priority: boolean;
  type: number;
  project_type: IProjectType;
  approved: boolean;
  property_id: number;
  project_property: IProjectProperty;
  current_budget: number; // ICashFlow
  contracts: number; // ICashFlow
  total_committed: number; // ICashFlow
  actuals: number; // ICashFlow
  forecasts_to_complete: number; // ICashFlow
  forecast_modifications: number; // ICashFlow
  total_invoiced: number; // ICashFlow
  // total_committed_vs_current_budget: number;
  budget_tags: IBudgetTag;
  complete: number;
  work_percentage: number;
  days_due: number;
  tags: string[];
  description: Partial<ProjectDescription>;
  project_id: string;
}

export interface IRollupProjectTableTotals {
  title?: string;
  total_projects?: string;
  current_budget: number;
  contracts: number;
  total_committed: number;
  actuals: number;
  forecasts_to_complete: number;
  total_committed_vs_current_budget: number;
  complete: number;
}

export interface IRollupLineItemTableTotals {
  title?: string;
  current_budget: number;
  total_committed: number;
  actuals: number;
  complete: number;
  total_committed_vs_current_budget: number;
}

export interface IRollupAccountsTableTotals {
  title?: string;
  current_budget: number;
  total_committed: number;
  actuals: number;
  complete?: number;
  total_committed_vs_current_budget: number;
}

export interface IRollupTagTotals {
  title?: string;
  total_committed: number;
  actuals: number;
  complete: number;
  actuals_vs_total_committed: number;
}

export interface IProjectStatus {
  id: number;
  name: string;
  key: ProjectStatusKey;
}

export interface IProjectType {
  id: number;
  name: string;
}

export interface IProjectProperty {
  id: number;
  user_id: number;
  type_unit_value: number;
  type_id: number;
  name: string;
  tax_id: string;
  street: string;
  city: string;
  state: string;
  zip: string;
  status: number;
  created_at: string;
  updated_at: string;
  deleted_at: any;
  formatted_address: string;
}

export interface ICashFlow {
  current_budget: number;
  contracts: number;
  total_committed: number;
  actuals: number;
  total_invoiced: number;
  forecast_modifications: number;
  forecasts_to_complete: number;
}

export interface IBudgetTag {
  capex: boolean;
  opex: boolean;
  priority: boolean;
  approved: boolean;
}

export interface IRollupLineItems {
  name: string;
  projects: IRollupLineItemProject[];
  cash_flow: IRollupGeneralCashFlow;
}

export interface IRollupLineItemTable {
  name: string;
  projects: IRollupLineItemProject[];
  current_budget: number;
  actuals: number;
  total_committed: number;
  total_committed_vs_current_budget: number;
  complete: number;
}

export interface IRollupAccount {
  name: string;
  items: IRollupAccountItems[];
  cash_flow: IRollupAccountCashFlow;
}

export interface IRollupAccountTable {
  name: string;
  items: IRollupAccountItems[];
  current_budget: number;
  actuals: number;
  total_committed: number;
  total_committed_vs_current_budget: number;
  complete: number;
}

export interface IRollupTag {
  name: string;
  items: IRollupTagItems[];
  cash_flow: IRollupTagCashFlow;
}

export interface IRollupTagTable {
  name: string;
  items: IRollupTagItems[];
  total_committed: number;
  actuals: number;
  complete: number;
  actuals_vs_total_committed: number;
}
export interface IRollupTagItems {
  id: number;
  name: string;
  cash_flow: IRollupTagCashFlow;
}

export interface IRollupTagCashFlow {
  total_committed: number;
  actuals: number;
}

export interface IRollupAccountItems {
  id: number;
  name: string;
  project_title: string;
  cash_flow: IRollupAccountCashFlow;
}

export interface IRollupAccountCashFlow {
  current_budget: number;
  actuals: number;
  total_committed: number;
}

export interface IRollupLineItemProject {
  id: number;
  title: string;
  cash_flow: CashFlow;
}

export interface CashFlow {
  total_committed: number;
  actuals: number;
}

export interface IRollupGeneralCashFlow {
  total_committed: number;
  actuals: number;
  current_budget: number;
}

export interface IRollupFilters {
  // we might extend later - these filters are stored, the other ones change
  project_status?: number[];
  property_ids?: number[];
}

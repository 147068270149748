import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import {
  IProjectFilters,
  ISimpleProject,
  Project,
  PROJECT_STATUS_KEY,
  ProjectBid,
  ProjectStatus,
} from '../pages/webapp/projects/projects.interface';
import {
  BUDGET_TEMPLATES,
  PROJECTS_SIMPLE,
  REST_PROJECT_BID,
  REST_PROJECT_INVITE,
  REST_PROJECT_STATS,
  REST_PROJECT_STATUS,
  REST_PROJECT_STATUS_TYPES,
  REST_PROJECT_TAGS,
  REST_PROJECTS,
  REST_PROPERTY,
  REST_PROPERTY_TYPES,
} from '../restApi/RestRoutes';
import { ErrorHandlerRest } from '../restApi/errorHandler-rest';
import { RestRequestService } from '../restApi/rest-request.service';
import { IProperty } from '../store/properties/properties.interfaces';
import { ROLLUP_VIEW_TYPES } from '../store/rollups/rollups.interface';
import { CurrentUserService } from './current-user.service';
import { InvitedContractor } from '../framework/constants/user.constants';
import { specialCharReplaceRegexp } from '../framework/constants/spend.constants';
import { IBudgetTemplate } from '../store/settings/settings.interface';

@Injectable({
  providedIn: 'root',
})
export class ProjectApiService extends ErrorHandlerRest {
  constructor(
    private rest: RestRequestService,
    protected user: CurrentUserService,
    protected router: Router,
  ) {
    super(user, router);
  }

  getRollup(
    view: ROLLUP_VIEW_TYPES,
    search: string = '',
    propertyIds: number[] = [],
    tags: string[] = [],
    projectStatusIds: number[] = [],
    projectIds: number[] = [],
    showZeroDollarLines: boolean = true,
  ) {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('view', view);
    queryParams = queryParams.append('search', search);
    queryParams = queryParams.append('showZeroDollarLines', showZeroDollarLines ? '1' : '0');
    propertyIds.forEach((propertyId, index) => {
      queryParams = queryParams.append(`propertyIds[${index}]`, propertyId);
    });
    projectStatusIds.forEach((projectStatusId, index) => {
      queryParams = queryParams.append(`projectStatusIds[${index}]`, projectStatusId);
    });
    projectIds.forEach((projectId, index) => {
      queryParams = queryParams.append(`projectIds[${index}]`, projectId);
    });
    queryParams = this.getTagsQueryParams(queryParams, tags);
    return this.rest.getWithObservable(REST_PROJECTS + `/rollup?${queryParams.toString()}`);
  }

  getTagsQueryParams = (queryParams: HttpParams, tags: string[]) => {
    if (!tags?.length) {
      return queryParams;
    }

    tags.forEach((tag, index) => {
      queryParams = queryParams.append(`tags[${index}]`, tag);
    });

    return queryParams;
  };

  getProjects(): Promise<Project[]> {
    return new Promise<any>((res, rej) => {
      this.rest.get(REST_PROJECTS + '?fiscal_view=1').then(
        (dataRes) => {
          res(dataRes);
        },
        (err) => {
          rej(this.handleError(err));
        },
      );
    });
  }

  getSimpleProjects(filter: IProjectFilters): Observable<ISimpleProject[]> {
    const statusFilter = { ...filter };
    statusFilter['status_filter[]'] = statusFilter.status_filter;
    delete statusFilter.status_filter;
    return this.rest.getWithObservable(PROJECTS_SIMPLE, {}, statusFilter).pipe(
      map((projects: ISimpleProject[]) => {
        if (!projects || !Array.isArray(projects)) {
          return [];
        }
        return projects.toSorted((a, b) => {
          const aField = a.title.replace(specialCharReplaceRegexp, '');
          const bField = b.title.replace(specialCharReplaceRegexp, '');
          return aField.localeCompare(bField);
        });
      }),
    );
  }

  getProject(id: number): Promise<Project> {
    return new Promise<any>((res, rej) => {
      this.rest.get(REST_PROJECTS + '/' + id + '?fiscal_view=1').then(
        (dataRes) => {
          res(dataRes);
        },
        (err) => {
          rej(this.handleError(err));
        },
      );
    });
  }

  getProjectById$(id: number) {
    return this.rest.getWithObservable(REST_PROJECTS + '/' + id + '?fiscal_view=1');
  }

  getProjectWithObservable(id: number): Observable<Project> {
    return this.rest.getWithObservable(`${REST_PROJECTS}/${id}`);
  }

  getBudgetTemplates(propertyId: number): Observable<IBudgetTemplate[]> {
    return this.rest.getWithObservable(BUDGET_TEMPLATES, {}, { property_id: propertyId });
  }

  createProject$(project: Partial<Project>) {
    return this.rest.postWithObservable(REST_PROJECTS, project);
  }

  updateProject(project): Promise<any> {
    const body = project;

    return new Promise<any>((res, rej) => {
      this.rest.put(REST_PROJECTS + '/' + project.id, body).then(
        (dataRes) => {
          res(dataRes);
        },
        (err) => {
          rej(this.handleError(err));
        },
      );
    });
  }

  updateProject$(project: Partial<Project>) {
    return this.rest.putWithObservable(`${REST_PROJECTS}/${project.id}`, project);
  }

  updateProjectStatus(id: number, status: number) {
    const body = {
      status,
    };

    return new Promise<any>((res, rej) => {
      this.rest.put(REST_PROJECT_STATUS + '/' + id, body).then(
        (dataRes) => {
          res(dataRes);
        },
        (err) => {
          rej(this.handleError(err));
        },
      );
    });
  }

  deleteProject$(id: number) {
    return this.getPossibleStatuses$().pipe(
      switchMap((statuses) => {
        const deletedStatus = statuses.find((status) => status.key === PROJECT_STATUS_KEY.DELETED);
        if (!deletedStatus.id) {
          throw new Error('Cannot delete project: deleted status not found');
        }
        return this.rest.putWithObservable(REST_PROJECT_STATUS + '/' + id, {
          status: deletedStatus.id,
        });
      }),
    );
  }

  getPossibleStatuses$(): Observable<ProjectStatus[]> {
    return this.rest.getWithObservable(REST_PROJECT_STATUS_TYPES);
  }

  getStats() {
    return new Promise<any>((res, rej) => {
      this.rest.get(REST_PROJECT_STATS).then(
        (dataRes) => {
          res(dataRes);
        },
        (err) => {
          rej(this.handleError(err));
        },
      );
    });
  }

  createProperty(property): Promise<any> {
    const body = property;

    return new Promise<any>((res, rej) => {
      this.rest.post(REST_PROPERTY, body).then(
        (dataRes) => {
          res(dataRes);
        },
        (err) => {
          rej(this.handleError(err));
        },
      );
    });
  }

  getProperties(): Promise<IProperty[]> {
    return new Promise<any>((res, rej) => {
      this.rest.get(REST_PROPERTY).then(
        (dataRes) => {
          res(dataRes);
        },
        (err) => {
          rej(this.handleError(err));
        },
      );
    });
  }

  updateProperty(id, body): Promise<any> {
    return new Promise<any>((res, rej) => {
      this.rest.put(REST_PROPERTY + '/' + id, body).then(
        (dataRes) => {
          res(dataRes);
        },
        (err) => {
          rej(this.handleError(err));
        },
      );
    });
  }

  deleteProperty$(id) {
    return this.rest.delWithObservable(REST_PROPERTY + '/' + id).pipe(catchError(this.handleError));
  }

  getPropertyTypes(): Promise<any> {
    return new Promise<any>((res, rej) => {
      this.rest.get(REST_PROPERTY_TYPES).then(
        (dataRes) => {
          res(dataRes);
        },
        (err) => {
          rej(this.handleError(err));
        },
      );
    });
  }

  createInvite(projectId: number, contractorIds: number[]): Promise<any> {
    const body = {
      project_ids: [projectId],
      contractor_ids: contractorIds,
    };

    return new Promise<any>((res, rej) => {
      this.rest.post(REST_PROJECT_INVITE, body).then(
        (dataRes) => {
          res(dataRes);
        },
        (err) => {
          rej(this.handleError(err));
        },
      );
    });
  }

  getInvitedContractors(projectId: number): Observable<
    {
      invited_contractors: InvitedContractor[];
      project_id: number;
      project_title: string;
    }[]
  > {
    return this.rest.getWithObservable(
      REST_PROJECT_INVITE + '/contractors?',
      {},
      { project_id: projectId },
    );
  }

  deleteInvite(id: number) {
    return this.rest.delWithObservable(REST_PROJECT_INVITE + '/' + id);
  }

  createBid(projectId, value) {
    value.project_id = projectId;
    const body = value;

    return new Promise<any>((res, rej) => {
      this.rest.post(REST_PROJECT_BID, body).then(
        (dataRes) => {
          res(dataRes);
        },
        (err) => {
          rej(this.handleError(err));
        },
      );
    });
  }

  updateBid(bidId: any, values: any) {
    const body = values;

    return new Promise<any>((res, rej) => {
      this.rest.put(REST_PROJECT_BID + '/' + bidId, body).then(
        (dataRes) => {
          res(dataRes);
        },
        (err) => {
          rej(this.handleError(err));
        },
      );
    });
  }

  updateBidStatus(bidID, status) {
    const body = {
      status,
    };

    return new Promise<any>((res, rej) => {
      this.rest.put(REST_PROJECT_BID + '/status/' + bidID, body).then(
        (dataRes) => {
          res(dataRes);
        },
        (err) => {
          rej(this.handleError(err));
        },
      );
    });
  }

  getBidByProject(projectID): Promise<ProjectBid[]> {
    return new Promise<any>((res, rej) => {
      this.rest.get(REST_PROJECT_BID + '?project_id=' + projectID).then(
        (dataRes) => {
          res(dataRes);
        },
        (err) => {
          rej(this.handleError(err));
        },
      );
    });
  }

  getAllProjectTags(): Promise<string[]> {
    return this.rest.get(REST_PROJECT_TAGS);
  }

  getNextProjectId(params: {
    property_id?: number;
    year?: number;
  }): Observable<{ next_custom_id: string }> {
    if (!params.property_id) {
      delete params.property_id;
    }
    if (!params.year) {
      delete params.year;
    }

    return this.rest.getWithObservable(REST_PROJECTS + '/next-custom-id', {}, params);
  }
}
